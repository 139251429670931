import ClassCard from "app/components/ClassCard";
import { Slider } from "app/components/Slider";
import { Routes } from "constants/routes";
import { isEmpty } from "lodash";
import React from "react";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { Section } from "../Section";
import { SavedClassesEmptyState } from "./EmptyState";

type SavedClassesProps = {
  data: object[];
  loading?: boolean;
};

export const SavedClasses = ({ data, loading }: SavedClassesProps) => {
  return (
    <Section
      title="Saved Classes"
      showLoadingState={loading}
      showEmptyState={isEmpty(data)}
      emptyStateComponent={<SavedClassesEmptyState />}
      headerLink={{
        route: Routes.saved,
        text: "See All",
      }}
    >
      <Slider
        data={data}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.SavedClasses}
            selectedFrom="SavedClasses"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
